import React,{useState, useEffect, useRef} from 'react'
import '../components/MainView/view_main.css'
import Layout from "../components/layout"
import { useMediaQuery } from 'react-responsive';
import useWindowSize from '../hooks/useGatsbyWindowSize'
import {gsap} from "gsap";
import Lenis from '@studio-freight/lenis';

const IndexPage = (props) => {


  const [isLoaded, setIsLoaded] = useState(false);
  const overlayIntroRef = useRef();

  const mobile = useMediaQuery({ query: `(orientation: portrait)` });
  const {width,height} = useWindowSize();

  //console.log( 'mobile :' + isMobile );


function __afterRun(ref){
  ref.remove();
}

useEffect(() => {
  setIsLoaded(true);
},[mobile]);

useEffect(() => {
gsap.to('#overlayFirst',1,{ delay:1, top:-70, opacity:1 });
});

if( mobile && isLoaded ){
return (

  <Layout mobile={mobile} bg={'light'}>
  <div id="overlayFirst" className="mobile home" style={{opacity:0, height:130+'vh'}}>
  {/*  <SvgEl className="-logoIntro" svg={LogoIntro.MAIN} /> */}
   <div id="menuFirst" className="mobile">
   <ul>
   <li><a style={{fontSize:1.8+'em!important'}} href="/decorative-art" className="cursor-scale grow-small" to="/decorative-art">DECORATIVE ARTS</a></li>
   </ul>
   </div>
   </div>

   <div className="bottomHealine">
   <h3 className="fDisplay cursive">Made In<br/>Los Ángeles</h3>
   </div>
   <div className="bottomHealineRight">

   </div>

  </Layout>



);
}
if( ! mobile && isLoaded ){
return (



    <Layout mobile={mobile} bg={'light'}>
    <div id="overlayFirst" className="home" style={{opacity:0, height:130+'vh'}}>
    {/*  <SvgEl className="-logoIntro" svg={LogoIntro.MAIN} /> */}
     <div id="menuFirst">
     <ul>
     <li><a href="/decorative-art" className="cursor-scale grow-small" to="/decorative-art">DECORATIVE ARTS</a></li>

     </ul>
     </div>
     </div>

     <div className="bottomHealine">
     <h3 id="counter" className="fDisplay cursive"></h3>
     </div>

    </Layout>

  );
}
}


export default IndexPage
